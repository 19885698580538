import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat';
import LoginForm from './components/Login'; // new import
import { v4 as uuidv4 } from 'uuid';

function getSessionId(alwaysNew: Boolean): string | null {
  let sessionId = sessionStorage.getItem('sessionId');
  if (!sessionId || alwaysNew) {
    let sessionId = uuidv4();
    sessionStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
}

const sessionId = getSessionId(true);

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/login" element={<LoginForm />} />:white
          <Route path="/" element={<Chat sessionId={sessionId}/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
