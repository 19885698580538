import { PostLogout, PostLogin } from './index';

export const login = async (username: string, password: string): Promise<string> => {
  try {
    const token = await PostLogin(username, password);
    sessionStorage.setItem('token', token.access_token);
    localStorage.setItem('username', username);
    return token;
  } catch (error) {
    console.error('Login failed', error);
    throw error;
  }
};

// clear token and username from session storage and navigate to login page
export const logout = async (): Promise<void> => {
  try {
    const response = await PostLogout();
    if (response.status === 200) {
      sessionStorage.removeItem('token');
      localStorage.removeItem('username');
        window.location.href = '/login';
    } else {
      console.error('Logout failed', response);
    }
  } catch (error) {
    console.error('Logout failed', error);
    throw error;
  }
};
