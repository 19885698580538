import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import { PostGptPrompt, PostGptInit } from "../api";
import animationData from "../assets/animations/woman-green.json";
import Wizard from "./Wizard";
import backgroundImg from "../assets/images/chat-background.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { LoginRoute } from "../constants";
import { logout } from "../api/auth";
import { useNavigate } from "react-router-dom";


interface Message {
  sender: "user" | "bot";
  content: string;
}

interface ApiResponse {
  response: string;
}

interface ChatProps {
  sessionId: string | null;
}

async function fetchWithMinDelay<T = unknown>(
  promise: Promise<T>,
  minDelay: number
): Promise<T> {
  const timeoutPromise = new Promise<void>((resolve) =>
    setTimeout(resolve, minDelay)
  );

  await timeoutPromise;

  const data = await promise;
  return data;
}

const Chat: React.FC<ChatProps> = ({ sessionId }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [initialMessageSent, setInitialMessageSent] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [wizardStep, setWizardStep] = useState(1);
  const [animate, setAnimate] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    setShowModal(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Redirects to login if username not found
    const username = localStorage.getItem("username");
    if (!username) {
      window.location.href = LoginRoute;
    }
  }, []);

  useEffect(() => {
    if (!showModal && !initialMessageSent) {
      const sendInitialMessage = async () => {
        try {
          const response = await PostGptInit(sessionId);
          const data = await response;
          setMessages([{ sender: "bot", content: data.response }]);
        } catch (error) {
          console.error("Error fetching GPT response:", error);
        }
      };
      // Wait 1000ms, then set loading response to true
      setTimeout(() => {
        setLoadingResponse(true);
        setTimeout(() => {
          setLoadingResponse(false);
          sendInitialMessage();
        }, 1000);
      }, 1000);

      setInitialMessageSent(true);
    }
  }, [showModal, initialMessageSent, sessionId]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.sender === "bot") {
      setAnimate(true);
    }
  }, [messages]);

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (loadingResponse) {
      scrollToBottom();
    }
  }, [loadingResponse]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (input.trim()) {
      const newMessages = [
        ...messages,
        { sender: "user" as const, content: input },
      ];
      setMessages([...newMessages]);
      setInput("");

      // Mimic loading response
      setTimeout(() => {
        setLoadingResponse(true);
      }, 1000);

      try {
        const data = await fetchWithMinDelay<ApiResponse>(
          PostGptPrompt(JSON.stringify({ prompt: input }), sessionId),
          1100
        );
        setLoadingResponse(false);
        setMessages([
          ...newMessages,
          { sender: "bot", content: data.response },
        ]);
      } catch (error) {
        console.error("Error fetching GPT response:", error);
        setLoadingResponse(false);
        setMessages([
          ...newMessages,
          { sender: "bot", content: "Sorry, something went wrong.  Please try again." },
        ]);
      }
    }
  };

  return (
    <>
      {showModal && (
        <Wizard
          wizardStep={wizardStep}
          setWizardStep={setWizardStep}
          closeModal={closeModal}
        />
      )}
      <div className="h-screen w-full md:flex">
        <div className="h-full w-full md:w-1/2 flex-none overflow-x-scroll snap-x">
          <div className="w-full h-full flex-none p-4 bg-white flex flex-col justify-center items-center">
            <div className="mb-12 w-1/2">
              <Lottie
                animationData={animationData}
                className="w-full"
                autoplay={true}
                loop={animate}
                onLoopComplete={() => setAnimate(false)}
              />
            </div>
            <button className="bg-gray-300 text-white font-bold py-10 px-12 focus:outline-none rounded-full" disabled>
              <i className="fas fa-microphone fa-3x"></i>
            </button>
          </div>
        </div>
        <div className="h-full w-full md:w-1/2 flex-none overflow-x-scroll snap-x">
          <div className="w-full h-full flex-none flex flex-col bg-battleship-grey">
            <div className="relative">
              <FontAwesomeIcon
              icon={faRightFromBracket}
              className="absolute top-2 right-10 cursor-pointer text-white mr-4 mt-4"
              onClick={() => {
                logout();
                navigate(LoginRoute);
              }}
              ></FontAwesomeIcon>
            </div>
            <div className="relative">
              <i
                className="fas fa-redo absolute top-2 right-2 cursor-pointer text-white mr-4 mt-4"
                onClick={() => window.location.reload()}
              ></i>
            </div>
            <div
              className="flex-grow overflow-auto"
              style={{
                backgroundImage: `url(${backgroundImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="max-w-2xl mx-auto py-8 px-4">
                {messages.map((message, index) => (
                  <div key={index}>
                    <div
                      className={`font-semibold text-xs ${
                        message.sender === "user"
                          ? "text-gray-300 text-right"
                          : "text-gray-400"
                      }`}
                    >
                      {message.sender === "user" ? "You" : "Gena"}
                    </div>
                    <div
                      className={`chat-bubble my-2 p-4 rounded-lg ${
                        message.sender
                      } ${
                        message.sender === "user"
                          ? "bg-light-mauve text-grey ml-auto"
                          : "bg-gray-700 text-white"
                      }`}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {message.content}
                    </div>
                  </div>
                ))}
                {loadingResponse && (
                  <div className="mb-4">
                    <div className="font-semibold text-xs text-gray-400">
                      Ricky
                    </div>
                    <div
                      className="chat-bubble my-2 p-4 pl-8 rounded-lg bg-gray-700 text-white bot"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} className="fa-beat" />
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef}></div>
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex items-center bg-charcoal-grey p-4"
            >
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Type your message..."
                autoComplete="off"
                className="flex-grow bg-gray-700 p-4 rounded-lg outline-none text-white"
              />
              <button
                type="submit"
                disabled={!input.trim()}
                className="ml-4 px-8 py-2 text-white bg-soft-green rounded-lg disabled:opacity-50"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
