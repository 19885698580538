import React from "react";
import backgroundImg from "../assets/images/wizard_background1.svg";

interface WizardStepProps {
  title: string;
  content: string;
}
const WizardStep: React.FC<WizardStepProps> = ({ title, content }) => (
  <div className="text-center overflow-auto h-2/3">
    {" "}
    {/* Add overflow-auto for scroll and define height */}
    <h2 className="text-2xl font-bold mb-4">{title}</h2>
    <p>{content}</p>
  </div>
);

interface WizardProps {
  wizardStep: number;
  setWizardStep: React.Dispatch<React.SetStateAction<number>>;
  closeModal: () => void;
}

const Wizard: React.FC<WizardProps> = ({
  wizardStep,
  setWizardStep,
  closeModal,
}) => {
  const nextWizardStep = () => {
    if (wizardStep < 3) {
      setWizardStep(wizardStep + 1);
    } else {
      closeModal();
    }
  };

  const wizardSteps = [
    {
      title: "Welcome to CancerIQ",
      content:
        "Welcome to CancerIQ, your companion for pretest genetic counseling. Our mission is to help you understand your genetic testing options and potential outcomes. We're here to guide you through the process, providing personalized and clear information every step of the way. Your privacy and data security are our top priorities, so you can navigate this journey with peace of mind.",
    },
    {
      title: "Step 2: Information",
      content:
        "In this session, you will learn more about the process of genetic counseling and how it can help you. We'll explain the different types of genetic tests available, potential implications of the results, and how this information can guide your health decisions. This knowledge will empower you to get the most out of your counseling session. It's normal to have questions or feel uncertain - this is your time to learn and understand.",
    },
    {
      title: "Step 3: Get Started",
      content:
        "You're now ready to begin your genetic counseling session. During the session, feel free to ask any questions or express any concerns you might have. Your comfort and understanding are our priority. After the session, you'll have a clearer picture of your genetic health and the possible steps forward. Remember, you can pause the session at any time and resume when ready. Let's begin this journey to a better understanding of your genetic health.",
    },
  ];

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 lg:p-12">
      <div className="bg-black opacity-75 fixed inset-0 z-10"></div>
      <div className="w-full h-full md:max-w-3xl mx-auto z-20 flex flex-col justify-between lg:max-w-5xl xl:max-w-6xl lg:rounded-xl lg:shadow-lg overflow-hidden">
        <div
          className="h-1/4 lg:h-1/2"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="h-3/4 lg:h-1/2 bg-white p-6 flex flex-col justify-between">
          <WizardStep
            title={wizardSteps[wizardStep - 1].title}
            content={wizardSteps[wizardStep - 1].content}
          />
          <div className="flex justify-between items-center sticky bottom-0 bg-white py-2">
            {" "}
            {/* Add sticky positioning and bg color */}
            {wizardStep > 1 && (
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none"
                onClick={() => setWizardStep(wizardStep - 1)}
              >
                Back
              </button>
            )}
            <button
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none"
              onClick={nextWizardStep}
            >
              {wizardStep === 3 ? "Start Session" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wizard;
