import axios from 'axios';
import { logout } from './auth';

let apiBaseUrl;
if (process.env.NODE_ENV === "production") {
  apiBaseUrl = `${window.location.origin}/api`;  // Use the reverse proxy in production
} else {
  apiBaseUrl = process.env.REACT_APP_BACKEND_URL_DEV;  // Directly connect to the backend in development
}

const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  if (config.url !== '/auth/login') {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
  }
  return config;
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    logout();  // Logout if the token is invalid
  }
  return Promise.reject(error);
});

export const PostGptInit = async (sessionId: string | null) => {
  try {
    const response = await api.post('/gpt/initial', {}, { headers: { 'X-Session-ID': sessionId } });
    return response.data;
  } catch (error) {
    console.error('Error fetching GPT response:', error);
    throw error;
  }
};

export const PostGptPrompt = async (prompt: string, sessionId: string | null) => {
  try {
    const response = await api.post('/gpt', { prompt }, { headers: { 'X-Session-ID': sessionId } });
    return response.data;
  } catch (error) {
    console.error('Error fetching response:', error);
    throw error;
  }
};

export const PostLogin = async (username: string, password: string) => {
  try {
    const response = await api.post('/auth/login', { username, password });
    return response.data;
  } catch (error) {
    console.error('Error while logging in:', error);
    throw error;
  }
};


// PostLogout function placeholder.  Do nothing for now.
// TODO: Implement logout
export const PostLogout = async () => {
  try {
    return { status: 200 };
  } catch (error) {
    console.error('Error while logging out:', error);
    throw error;
  }
}
